// base colors
$color-primary: #272727;
$color-secondary: #269fdb; //#ff7274;

$color-step1: $color-secondary;
$color-step2: #1d71bb; //#bd4d79;
$color-step3: #2d429a; //#622c8c;
$color-step4: #5f3b97; //#5b7acb;
$color-step5: #b3228f; //#5bb5f3;
$color-casestudy: #3e3e3e;

$variantColors: (
    "step1": $color-step1,
    "step2": $color-step2,
    "step3": $color-step3,
    "step4": $color-step4,
    "step5": $color-step5,
    "case-study": $color-casestudy,
) !default;

$color-dark1: $color-primary;
$color-dark2: #3f3f3f;
$color-dark3: #7f7f7f;

$color-light1: #efefef; // e5e5e5
$color-light2: #cccccc;
$color-light3: #d9d9d9;
$color-light4: #AEAEAE;
$color-light5: #707070;

$color-white: #ffffff;
$color-black: #000000;
