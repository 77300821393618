@import "../../assets/styles/utils/index";

// TODO: usunac niepotrzebne style (po skopiowaniu z innego pliku cos moze być nie potrzebne)

$ProductDeliveryProccess_transition: cubic-bezier(0.15, 1.01, 0.35, 0.94);

@include mediaQueries(M, 'min') {

.c-ProductDeliveryProcess {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__heading {
        font-weight: 700;
        @include fontSize($fontSize-variant3);
        text-align: center;
        margin: 0 6.5rem;
    }

    &__processes {
        margin: $space_2 0 0 0;
        display: flex;
        flex-direction: row;
        overflow: hidden;
        position: relative;
        flex-wrap: wrap;
        width: 100%;

        &:before {
            content: "";
            position: absolute;
            height: 1px;
            background-color: $color_light2;
            top: 28px;
            transform: translateY(-50%);
            width: 100%;
        }
    }
}

.process {
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    width: 100%;
}

@keyframes progressBar{
    from{
        width: 0px;
    }
    to{
        width: 190px;
    }
}
@keyframes progressBarLastItem{
    from{
        width: 0px;
    }
    to{
        width: 95px;
    }
}
@keyframes progressBarFirstItem{
    from{
        width: 0px;
        
    }
    to{
        
        width: 95px;
    }
}
.process-info {
    display: flex;
    align-items: center;
    position: relative;
    flex-direction: column;
    width: 20%;
    order: 1;
    margin-bottom: $space_1 + $spaceInsideElement;
    cursor: pointer;

    &__number {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 55px;
        height: 55px;
        font-weight: 700;
        line-height: 100%;
        @include fontSize($fontSize-variant3);
        transition: all 0.3s ease;

        &:not(.is-active) {
            color: $color-black;
            background: $color-white;
            transition: all 0.6s ease;
        }
        &.is-active{
            transition: all 0.5s ease-out;
        }
      
        &--loading{
            position: absolute;
            left: 146px;
            top: 28px;
            height:1px;
            width: 0px;
            transform: translateY(-50%);
            background-color: #1f52a5;
            transition: all 0s linear;
            &-firstItem{
                position: absolute;
                left: 0px;
                top: 28px;
                height:1px;
                //width: 95px;
                width: 0px;
                transform: translateY(-50%);
                background-color: #1f52a5;
                transition: all 0s linear;
            }
            
            &.is-active {
                animation-name: progressBar;
                animation-duration: 4s;
                animation-iteration-count: infinite;
                animation-timing-function: linear;
                animation-play-state: running;

                &.paused{
                    animation-play-state: paused;
                }

            }
            &.last-item-animation{
                animation-name: progressBarLastItem;
                animation-duration: 2s;
                animation-iteration-count: 1;
                animation-timing-function: linear;
                &.paused{
                    animation-play-state: paused;
                }
            }
            &.first-item-animation{
                animation-name: progressBarFirstItem;
                animation-duration: 2s;
                animation-iteration-count: 1;
                animation-delay: 2s;
                animation-timing-function: linear;
                left:0px;
                &.paused{
                    animation-play-state: paused;
                }
            }
        }
        
    }

    &__name {
        font-weight: 700;
        font-size: 2rem;
        text-transform: uppercase;
        text-align: center;
        margin-top: 1.5rem;
    }

    &__btn--collapse {
        display: none;
    }
    &__btn--arrow{
        width: 20px;
        position: relative;
        top: 249px;
        cursor: pointer;
        user-select: none;
        opacity: 0.4;

        &:hover{
            opacity: 1.0;
        }

        &-left{
            left: -3%;
        }
        &-right{
            left: 100%;
        }
    }
    
    
}
@media all and (min-width: 1201px) and (max-width: 1280px){
    .process-info {
        &__btn--arrow{
            z-index: 2;
            &-left{
                left: -0.3%;
            }
            &-right{
                left: 97%;
            }      
        }
    }
}
.process-details {
    // padding: $spaceInsideElement $space_1 0 $space_1;
    padding: 0 $space_1;
    // display: none;
    width: 100%;
    order: 2;
    position: relative;
    top: 0;
    // top: $spaceInsideElement; // -5rem
    left: -100%;
    display: flex;
    opacity: 0;
    visibility: hidden;
    height: 0;
    // transition: all 0.5s $ProductDeliveryProccess_transition;
    transition: opacity 1s ease;

    &.is-active {
        // display: flex;
        left: 0;
        visibility: visible;
        height: auto;
        opacity: 1;
        
    }

    &__text {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        margin-right: $space_2;
        height: 100%;
        flex-basis: 90%;
        position: relative;
        left: -100%;
        
        &__title {
            font-weight: 700;
            @include fontSize($fontSize_variant3);
            margin-bottom: $space_3;
            min-height: 5rem;
        }

        &__description {
            font-weight: 300;
            line-height: 2.5rem;
            margin-bottom: $space_3;
            width: 70%;
            @include fontSize($fontSize_variant5);
        }

        &__more {
            display: inline-flex;
            text-align: center;
            font-weight: 700;
            padding: 1rem 2.5rem;
            @include fontSize($fontSize_variant5);
            text-transform: uppercase;
            border-bottom: 1px solid $color-dark3;
            letter-spacing: 0.2rem;
            text-decoration: none;
            line-height: 2rem;
            color: #000000;
        }

        .process-details.is-active & {
            transition: all 0.5s $ProductDeliveryProccess_transition;
            left: 0;
        }
    }

    &__includedElements {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex-basis: 30%;
        position: relative;
        right: -100%;

        &__title {
            font-weight: 700;
            font-size: 2.4rem;
            margin-bottom: $space_3;
        }

        .process-details.is-active & {
            transition: all 0.6s $ProductDeliveryProccess_transition;
            right: 0;
        }
    }

    &__includedElement {
        font-weight: 700;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 1.5rem 3rem;
        margin-bottom: 0.6rem;
        @include fontSize($fontSize_variant5);
        text-transform: uppercase;
        background-color: transparent;
        height: 40px;
        color: $color-white;
        &.color-txt--white {
            color: $color-white;
        }
    }
}
}
