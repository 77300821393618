p {
    color: inherit;// $color-primary;
    line-height: 1.5;
}

i, em {
    font-style: italic;
}

em {
    &:before {
        content: open-quote;
    }
    &:after {
        content: close-quote;
    }
}

strong, b{
    font-weight: bold;
}

// q, blockquote
blockquote:before, blockquote:after,
q:before, q:after { content:''; content:none; }

q, blockquote {
    font-style: italic;
    font-weight: 300;
}

// Text styles

.text-style {
    &--upp {
        text-transform: uppercase;
    }
}


// Headings
h1, h2, h3, h4, h5, h6 {
    line-height: 1.2;
    font-weight: bold;
}
// FIXME: dopasować wielkosci nagłówków
.h1 {
    @include fontSize( map_get($variantFontSizes, 'variant3_2') );
    
    @include mediaQueries(S, 'min'){
        @include fontSize( map_get($variantFontSizes, 'variant3_1') );
    }
    
    @include mediaQueries(M, 'min'){
        @include fontSize( map_get($variantFontSizes, 'variant2') );
    }
}
.h2 {
    @include fontSize( map_get($variantFontSizes, 'variant3') );
    
    @include mediaQueries(S, 'min'){
        @include fontSize( map_get($variantFontSizes, 'variant3_2') );
    }
    
    @include mediaQueries(M, 'min'){
        @include fontSize( map_get($variantFontSizes, 'variant3') );
    }
}
.h3 {
    @include fontSize( map_get($variantFontSizes, 'variant5') );

    @include mediaQueries(M, 'min'){
        @include fontSize( map_get($variantFontSizes, 'variant4') );
    }
}
.h4 {
    @include fontSize( map_get($variantFontSizes, 'variant6') );

    @include mediaQueries(M, 'min'){
        @include fontSize( map_get($variantFontSizes, 'variant5') );
    }
}
.h5 {
    @include fontSize( map_get($variantFontSizes, 'variant6') );
}
.h6 {
    @include fontSize( map_get($variantFontSizes, 'variant7') ); 
}

// Lists
ol, ul {
    text-align: left;
    padding-left: 2rem;
    &.reset {
        list-style: none;
        padding: 0;
        margin-left: 0;
        margin-right: 0;
        margin-top: 0;

        li { padding: 0; }
    }
    li { line-height: 1.5; }
}

li { padding: 0 0 1rem 1rem; }

ul {
    list-style: square;
    > ul {
        list-style: disc;
        ul {
            list-style: circle;
        }
    }
    ul {
        li { padding-left: 0; }
    }
    ol, ul {
        margin-top: 1rem;
        padding-left: 2rem;
        li { padding-left: 0; }
    }
}

ol {
    list-style-type: decimal;
    > ol {
        list-style-type: decimal;
        ol {
            list-style: disc;
        }
    }

    > ul {
        list-style-type: square;
        ul {
            list-style-type: circle;
        }
    }

    ol, ul {
        margin-top: 1rem;
        padding-left: 2rem;
        li { padding-left: 0; }
    }
}


// Fonts
@each $variantFontSizeClass, $variantFontSize in $variantFontSizes {
    .font-size--#{$variantFontSizeClass} {
      font-size: #{$variantFontSize};
    }
}

// Fonts weights
.font-weight {
    &--300 {
        font-weight: 300;
    }
    &--400 {
        font-weight: 400;
    }
    &--700 {
        font-weight: 700;
    }
}


// Other styles
.clip-text {
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    position: relative;
    &:before,
    &:after {
        position: absolute;
        content: '';
    }
    &--gradient {
        &-base {
            @include background-clip-text($color-primary, $baseGradientSettings);
            // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8dd010', endColorstr='#15b639', GradientType=1 );
            // background: -ms-linear-gradient(left, rgba(21, 182, 57,1) 0%, rgba(141, 208, 16,1) 10%);

        }
        @each $variantColorClass, $variantColor in $variantColors {
        &-#{$variantColorClass} {
            @include background-clip-text($variantColor, baseGradientLinearSettingsByIdName($variantColorClass));
            // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8dd010', endColorstr='#15b639', GradientType=1 );
        }
        }
    }
}
