* {
  box-sizing: border-box;

  &:empty {
    margin: 0;
    padding: 0;
  }
}

// Base selection colors
@include selection();
// Selection colors by step class
@include selectionByStep(step1);
@include selectionByStep(step2);
@include selectionByStep(step3);
@include selectionByStep(step4);
@include selectionByStep(step5);

html {
  font-size: 62.5%;
  font-size\0: 63%;
}

body {
  font-size: $fontSize-default;
  font-family: "Poppins", sans-serif;
  color: $color-primary;
  line-height: 1;
  background-color: $color-primary;
  -webkit-text-size-adjust: none; 
  text-size-adjust: none;
}

// Main sections of page - placeholders
#root {
  min-height: 100vh;
  background-color: $color-white;
  overflow: hidden;
}

#header,
#main {
  position: relative;
}
#header {
  z-index: 1;
}
#main {
  z-index: 0;
}
iframe {
  border: none;
}

img {
  max-width: 100%;
  height: auto;
}

// text aligment
.align {
  &-center {
    text-align: center !important;
  }

  &-left {
    text-align: left !important;
  }

  &-right {
    text-align: right !important;
  }

  &-justify {
    text-align: justify !important;
  }
}

.is-hide {
  display: none !important;
}

.is-show {
  display: block !important;
}

.is-hidden {
  visibility: hidden !important;
}

.is-visible {
  visibility: visible !important;
}

[hidden] {
  display: none !important;
}

[disabled] {
  cursor: not-allowed;
}

// :focus:not(:focus-visible) {
//     outline: none;
// }

// for screenreaders
@mixin sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.sr-only {
  @include sr-only;
}

/*
 * only suppress the focus ring once what-input has successfully started
 */

/* suppress focus ring on form controls for mouse users */
[data-whatintent="mouse"] *:focus {
  outline: none;
}
