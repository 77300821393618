@mixin baseStylesInputs {
  border: none;
  border-bottom: 1px solid #9c9c9c;
  line-height: 2;
}

@mixin resetStylesFields {
  -webkit-appearance: none;
  border-radius: 0;
}

// custom radio and checkbox
@mixin vh() {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

// fields
.component {
  fieldset {
    legend {
    }
  }

  textarea,
  input[type="text"],
  input[type="button"],
  input[type="submit"],
  input[type="email"] {
    @include resetStylesFields;
  }

  input {
    &::placeholder {
    }
  }

  textarea {
    &::placeholder {
    }
  }

  select {
    @include resetStylesFields;
  }

  select::-ms-expand {
    display: none;
  }

  input {
    &[type="text"] {
    }
  }
  textarea {
    min-width: inherit;
    max-width: inherit;
    max-height: 100%;
    resize: vertical;
  }

  button[type="submit"] {
  }
}
