// Base font sizes
$fontSize-default: 18px;

$fontSize-variant0_1: 90px;
$fontSize-variant1: 72px;
$fontSize-variant1_1: 60px;
$fontSize-variant2: 42px;
$fontSize-variant3: 32px;
$fontSize-variant3_1: 30px;
$fontSize-variant3_2: 24px;
$fontSize-variant3_3: 21px;
$fontSize-variant3_4: 20px;
$fontSize-variant4: $fontSize-default;
$fontSize-variant5: 16px;
$fontSize-variant5_1: 15px;
$fontSize-variant6: 14px;
$fontSize-variant6_1: 13px;
$fontSize-variant7: 12px;

$variantFontSizes: (
    "variant0_1": $fontSize-variant0_1,
    "variant1": $fontSize-variant1,
    "variant1_1": $fontSize-variant1_1, 
    "variant2": $fontSize-variant2,
    "variant3": $fontSize-variant3,
    "variant3_1": $fontSize-variant3_1, 
    "variant3_2": $fontSize-variant3_2,
    "variant3_3": $fontSize-variant3_3,
    "variant4": $fontSize-variant4, 
    "variant5": $fontSize-variant5, 
    "variant5_1": $fontSize-variant5_1, 
    "variant6": $fontSize-variant6,
    "variant6_1": $fontSize-variant6_1,
    "variant7": $fontSize-variant7
) !default;
