@mixin fontSize($size) {
  //font-size: $size;
  font-size: calculateRem($size);
}

@mixin fontSizeVariant($varinatName) {
  @include fontSize(map_get($variantFontSizes, $varinatName));
}
// @mixin fontSizeWithMediaQueries($baseFontSize) {
//     @include fontSize($baseFontSize);
//     @include mediaQueries(S, 'min') {
//         @include fontSize($baseFontSize);
//     }
//     @include mediaQueries(M, 'min') {
//         @include fontSize($baseFontSize);
//     }
//     @include mediaQueries(L, 'min') {
//         @include fontSize($baseFontSize);
//     }
// }

// http://nimbupani.com/using-background-clip-for-text-with-css-fallback.html
// Modernizr contains no detect for background-clip: text;
@mixin background-clip-text($base, $gradientSettings) {
  color: $base;
  background: linear-gradient($gradientSettings), transparent;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

@mixin textSpaceSize() {
  @include fontSize(map_get($variantFontSizes, "variant5"));
  line-height: 1.5;
  margin-top: 1em;

  @include mediaQueries(S, "min") {
    @include fontSize(map_get($variantFontSizes, "variant4"));
  }
}
