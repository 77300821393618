@import "../../assets/styles/utils/index";
.c-TouchCTA {
  font-family: 'Poppins', sans-serif;
  width: 6.6rem;
  height: 13.7rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  box-shadow: 0px 6px 29px #00000040;
  margin: 2rem 0;
  position: fixed;
  top: 12rem;
  right: 2rem;
  background-color: white;
  &__icon {
    width: 2.9rem;
    height: 2.9rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;
    cursor: pointer;
    padding: 0;

    &-main {
      width: 6rem;
      height: 6rem;
    }
    &-wrapper {
      display: flex;
      a {
        border: none;
        transition: none;
        &:hover {
          transition: none;
          border: none;
        }
      }
    }
    &-phone {
      background-color: #6a3895;
      margin-right: 0.2rem;
    }
    &-message {
      background-color: #1c50a5;
    }
  }
  &__text {
    &-top {
      font-size: 1rem;
      font-weight: 600;
    }
    &-bottom {
      font-size: 1.4rem;
      text-transform: uppercase;
      font-weight: bold;
    }
  }
  &__close {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
    border: 0;
    color: rgba(0,0,0, 0.6);
    width: 2rem;
    height: 2rem;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-content: center;
    padding: 0;
    font-size: 1.5rem;
  }

  @include mediaQueries(M, "min") {
    top: 19.5rem;
  }
  &__contactButton {
    font-family: "Poppins",sans-serif;
    padding: 0;
    border: 0;
    background-color: transparent;
    cursor: pointer;
    color: $color-black;
  }
}
