
@mixin headerSpace() {
    margin-bottom: $headerSpace_mobile;
    @include mediaQueries(S, 'min'){
        margin-bottom: $headerSpace;
    }
}

@mixin headerSpaceSize() {
    font-weight: 700;
    @include fontSize( map_get($variantFontSizes, 'variant3_2') );
    margin-bottom: $headerSpace_mobile;
    
    @include mediaQueries(S, 'min'){
        margin-bottom: $headerSpace;
        @include fontSize( map_get($variantFontSizes, 'variant3_1') );
    }
    
    @include mediaQueries(M, 'min'){
        @include fontSize( map_get($variantFontSizes, 'variant2') );
    }
}
