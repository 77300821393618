// RESET Styles
#header nav {
  > ul {
    padding-left: 0;
  }
}

// Component Styles
.c-Navigation {
  position: relative;
  text-align: center;
  font-size: 12px;
  padding-bottom: $desktop-nav-space;

  ul {
    list-style: none;
  }

  li {
    padding-right: 0;
    padding-bottom: 0;
    line-height: 1.5;
  }

  a {
    border-bottom-width: 1px;
    border-bottom-style: solid;
    text-decoration: none;
    color: inherit;
    position: relative;
  }

  @media (min-width: 360px) and (orientation: portrait) {
    font-size: 16px;
  }

  @media (min-width: 400px) and (orientation: portrait) {
    font-size: 18px;
  }

  @include mediaQueries(S, "min") {
    font-size: 18px;
  }

  // top
  &__hdr {
    display: block;
    padding-top: 48px;
    text-align: center;

    &--inside {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 10;
    }
  }

  &__logo {
    display: inline-block;
    max-width: 224px;
    width: 60%;
    border-color: transparent;
    vertical-align: top;

    @include mediaQueries(M, "min") {
      max-width: 294px;
      width: 294px;
    }

    img {
      display: block;
      max-width: 100%;
      height: auto;
    }
  }

  // btn
  &__trigger {
    position: fixed;
    top: -5px;
    right: 0;
    z-index: 3;
    border: 0;
    background: none;
    appearance: none;

    padding: $btn-pad + $slice-h + $slice-space $btn-pad;
    height: $btn-pad * 2 + $slice-h * 3 + $slice-space * 2;
    width: $slice-w + $btn-pad * 2;

    span {
      position: absolute;
      top: $btn-pad + $slice-h + $slice-space;
      //left: $btn-pad;
      width: $slice-w;
      height: $slice-h;
      display: block;
      background-color: $slice-bg;
      border-radius: $slice-radius;
      transform-origin: center;
      box-shadow: 1px 1px 0 0 transparentize($slice-bg-open, 0.5);
    }

    span:nth-child(2) {
      transform: translateY(-$slice-h - $slice-space);
    }

    span:nth-child(3) {
      transform: translateY($slice-h + $slice-space);
    }
  }

  &__language {
    @include mediaQueries(M, "max") {
      display: block !important;
    }

    display: none !important;
    position: fixed !important;
    top: 52px;
    right: 21px;
    z-index: 0;
    border: 0;
    background: none;
    appearance: none;
    padding: 0 !important;
    font-size: 1.7rem !important;
  }

  // nav
  // structure elements
  &__list {
    position: fixed;
    top: 0;
    transition: right 0.3s ease;
    height: $mobile-nav-h;
    overflow: hidden;
    z-index: 2;

    width: 0;
    transform: translateX($mobile-nav-w);
    animation: navhide_mobile $nav-animation-duration + 0.3s steps(1, end)
      forwards;

    @include mediaQueries(M, "max") {
      right: -100%;
    }

    > .c-Navigation__inner {
      top: 0;
      height: $mobile-nav-h;
      padding-top: 150px;
    }

    &--hidden{
      animation: none;
    }
  }

  &__inner {
    position: absolute;
    top: 120px;
    left: 0;
    width: $mobile-nav-w;
    height: calc(#{$mobile-nav-h} - 118px);

    @include mediaQueries(M, "max") {
      height: calc(#{$mobile-nav-h} - 184px);
    }

    background-image: linear-gradient(
      to right,
      $nav-bg-start 0%,
      $nav-bg-end 100%
    );
    color: $mobile-txt-color;
    text-align: left;
    overflow: hidden;
    padding-left: 0;
    margin-top: 0;
  }

  &__group {
    overflow: auto;
    height: 99.99%; //or 75%
    margin-top: 0;
    padding-left: 0;
    margin-bottom: 100px;
    @include mediaQueries(M, "min") {
      margin-bottom: 0;
    }

    // &ul {
    //   text-align: center;
    //   padding: 0;
    // }
  }

  // &__subgroup {}
  &__subgroup {
    &:last-child {
      .c-Navigation__subitem {
        &:last-child {
          margin-bottom: 65px;

          @include mediaQueries(M, "min") {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  &__column {
    margin-top: 0;
    padding-left: 2rem;
  }

  &__item,
  &__subitem {
    display: block;
    overflow: hidden;

    &--button {
      display: flex;
      justify-content: center;
    }
  }

  &__item {
    text-align: center;
    &:last-child {
      position: relative;

      @include mediaQueries(M, "max") {
        display: flex;
        justify-content: center;
        border-top: 1px solid #555;
        &:before {
          display: none;
        }
      }

      &:before {
        height: 50%;
        content: "";
        position: absolute;
        border: 1px solid #cdcdcd;
        top: 0;
        left: 0;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  // view elements
  &__anchor,
  &__groupanchor {
    display: block;
    margin: $mobile-anchor-marg;
    padding: $mobile-anchor-pad;
    border-color: transparent;
    font-size: $mobile-anchor-fs;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    width: 100%;
  }

  &__button {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    color: #272727;
  }

  &__anchor {
    &.active {
      color: $nav-highlight-color1;
    }
  }

  &__groupanchor {
    &--back {
      padding-left: 40px + 30px;
      color: #1f7cc3;
      font-size: $mobile-anchor-back;
      position: relative;
      text-align: left;
      background-color: transparent;
      border-bottom-width: 1px;
      transition: all 0.3s ease-out;
    }

    &--dropdown {
      background-color: white;
      position: relative;
      cursor: pointer;

      @include mediaQueries(M, "max") {
        background-color: transparent;
        color: white;
      }
    }

    &--lng {
      @include mediaQueries(M, "max") {
        width: auto;
        padding: 16px;
        color: #1f7cc3;
        cursor: auto;

        svg {
          display: none;
        }
      }

      + ul {
        @include mediaQueries(M, "max") {
          .c-Navigation__anchor {
            padding: 16px;
          }
        }
        @include mediaQueries(M, "max") {
          position: relative;
          display: flex !important;
          background-color: transparent;
          width: auto;
        }

        display: none;
        position: absolute;
        left: 0;
        padding: 0;
        margin: 0;
        width: 100%;
        background-color: #efefef;

        &.is-expanded {
          display: block;
        }
      }
    }
  }

  &__title {
    margin: $mobile-anchor-marg;
    padding: $mobile-anchor-pad;
    font-size: $mobile-title-fs;
    font-weight: 300;
    color: $nav-highlight-color1;
  }

  &__arrowright,
  &__arrowleft {
    position: absolute;
    right: 34px;
    top: 50%;
    margin-top: -11px;
    height: 22px;
  }

  &__arrowright {
    right: 34px;

    path {
      fill: $mobile-txt-color;
    }
  }

  &__arrowleft {
    left: 40px;

    path {
      fill: $nav-highlight-color1;
    }
  }

  // open state
  &--open {
    .c-Navigation {
      // btn
      &__trigger {
        span {
          &:nth-child(1) {
            animation: makex_mid 0.5s forwards;
          }

          &:nth-child(2) {
            animation: makex_top 0.5s forwards;
          }

          &:nth-child(3) {
            animation: makex_bot 0.5s forwards;
          }
        }
      }

      //nav
      &__list {
        //width: $mobile-nav-w;
        //transform: translateX(0);
        right: 0;
        animation: navshow_mobile 0.1s steps(1, start) forwards;
      }
    }
  }

  // hidden state
  &--hidden{
    .c-Navigation {
      //nav
      &__list {
        right: 0;
        animation: none;
    }
  }
}

  // active subgroup, animations
  &__inner {
    transform: translateX(100%);
    animation: subgrouphide $nav-animation-duration ease-in forwards;

    &--active {
      animation: subgroupshow $nav-animation-duration ease-out 0.1s forwards;
    }

    &--hidden{
      animation: none;
    }
  }

  // landscape modifications
  @media (orientation: landscape) {
    @include mediaQueries(S, "max") {
      //   &__hdr {
      //     padding-top: 20px;
      //   }

      &__list {
        > .c-Navigation__inner {
          padding-top: 100px;
        }
      }

      &__inner {
        top: 80px;
        height: calc(#{$mobile-nav-h} - 78px);
      }

      &__item {
        width: 50%;
        float: left;
      }

      &__subitem {
        width: 50%;
        float: left;

        &:nth-child(odd) {
          clear: left;
        }
      }

      &__title {
        clear: left;
      }
    }
  }

  @include mediaQueries(S, "min") {
    &__inner {
      width: $tablet-nav-w;
    }

    &__list {
      animation: navhide_tablet $nav-animation-duration + 0.3s steps(1, end)
        forwards;
    }

    &--open {
      .c-Navigation {
        &__list {
          //width: $tablet-nav-w;
          animation: navshow_tablet 0.1s steps(1, start) forwards;
        }
      }
    }

    &--hidden {
      .c-Navigation {
        &__list {
          animation: none;
        }
      }
    }
  }

  @include mediaQueries(M, "min") {
    &__trigger,
    &__hdr--inside,
    &__groupanchor--back {
      display: none;
    }

    &__inner,
    &--open .c-Navigation__list,
    &__list {
      width: $desktop-nav-w;
    }

    &__list {
      position: relative;
      transform: none;
      height: auto;
      overflow: visible;
      animation: none;

      > .c-Navigation__inner {
        padding-top: 18px;
        height: auto;
        transform: none;
        background: none;
        position: relative;
        overflow: visible;
      }
    }

    &__inner {
      color: $desktop-txt-color;
      text-align: center;
      transform: translateY($desktop-nav-space);
      height: 0;
      top: 100%;
      overflow: hidden;
      animation: none;
      display: flex;
      justify-content: center;
      align-items: center;
      // transition: height 0.3s;

      &--active {
        min-height: $desktop-nav-h;
        height: auto;
        // transition: height 0.3s;
      }
    }

    &__group {
      height: auto;
      overflow: visible;

      .c-Navigation__group {
        max-width: $layout-container;
        margin-left: auto;
        margin-right: auto;
      }
    }

    &__item {
      display: inline-block;
      overflow: visible;
      padding: 0;
    }

    &__anchor,
    &__groupanchor {
      padding: $desktop-anchor-pad-v $desktop-anchor-pad-h;
      font-size: $desktop-anchor-fs;
      font-weight: 700;
      text-transform: uppercase;
      letter-spacing: 0.05em;
      transition: color 0.3s ease-out, transform 0.3s ease-out;

      &:hover {
        color: $nav-hover-color;
        transition: color 0.2s ease-out, transform 0.2s ease-out;

        .c-Navigation__arrowright {
          path {
            fill: $nav-hover-color;
          }
        }
      }
    }

    &__groupanchor {
      margin-right: $desktop-anchor-pad-h;

      &--active {
        color: $nav-highlight-color1;

        .c-Navigation__arrowright {
          transform: rotate(-90deg);

          path {
            fill: $nav-highlight-color1;
          }
        }
      }
    }

    &__arrowright {
      right: 2px;
      margin-top: -6px;
      height: 10px;
      transform: rotate(90deg);
      transition: inherit;

      path {
        fill: $desktop-txt-color;
      }
    }

    &__column {
      position: relative;
      flex: 0 0 25%;
      text-align: left;
      padding-left: 36px - $desktop-anchor-pad-h;
      min-height: 40px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex-wrap: wrap;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 5px;
        bottom: 5px;
        width: 1px;
        background-color: rgba(255, 255, 255, 0.2);
      }
    }

    &__title {
      font-size: $desktop-title-fs;
    }

    &__subgroup {
      display: flex;
      color: $mobile-txt-color;

      &--huge {
        justify-content: center;
        align-items: flex-start;

        .c-Navigation {
          &__title {
            flex: 0 0 50%;
            font-size: $huge-title-fs;
            line-height: 1.1;
            text-align: right;
            padding: 0;
            margin: 0 50px 0 0;
          }

          &__column {
            flex: 0 0 50%;
            padding-left: 50px - $desktop-anchor-pad-h;
            min-height: 96px;

            &::before {
              top: 10px;
              bottom: 10px;
            }
          }

          &__anchor {
            margin: 5px 0;
            font-size: $huge-anchor-fs;
          }
        }
      }

      &--cols {
        .c-Navigation {
          &__title {
            padding-right: 0;
            margin: 0 36px 0 0;
          }
        }
      }
    }

    &__group--huge {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }

    &__group--cols {
      display: block;
      columns: 2;
      column-span: none;
      padding: 90px 0;
      height: inherit;

      .c-Navigation {
        &__title {
          flex: 0 0 222px;
          text-align: right;
          padding-top: 4px;
          line-height: 1.2;
        }

        &__subgroup {
          page-break-inside: avoid;
          break-inside: avoid-column;
          margin-bottom: 40px;
        }

        &__column {
          flex: 0 0 320px;
        }

        &__anchor {
          font-size: $desktop-anchor-fs + 1;
        }
      }
    }
  }
}
