@mixin baseButtonStyles() {
  font-size: inherit;
  text-transform: uppercase;
  font-weight: 700;
  color: inherit;
  letter-spacing: 0.2rem;
  display: inline-block;
  padding: 0.5rem 1.25rem;
  line-height: 2rem;
  position: relative;
  z-index: 0;
}

.component a {
  // text-decoration: underline;
  // &:hover {
  //     text-decoration: none;
  // }
  border-bottom-width: 1px;
  border-bottom-color: inherit;
  border-bottom-style: solid;
  transition: all 0.3s ease-out;

  text-decoration: none;
  color: inherit;

  &:after {
    height: 1px;
    background-color: inherit;
  }
  &:hover {
    border-bottom: 1px solid transparent;
    transition: all 0.2s ease-in;
  }

  &:not(.btn):not(.btn-elevator):not(.reset) {
    position: relative;
    &:after {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      left: 0;
      transition: inherit;
      opacity: 1;
      bottom: 0;
    }
    &:hover {
      &:after {
        transition: inherit;
        width: 0;
        left: 50%;
        opacity: 0;
      }
    }
  }

  &.reset {
    border: none;
    transition: none;
  }
}

.btn {
  // @at-root a, .btn {
  //     text-decoration: underline;
  //     &:hover {
  //         text-decoration: none;
  //     }
  // }
  @include baseButtonStyles();
  // @at-root {
  //     a#{&} {
  //         // text-decoration: none;
  //     }
  // }
  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    left: 0;
    transition: inherit;
    height: 1px;
    top: 100%;
    z-index: -1;
  }
  &:hover {
    border-bottom: 1px solid transparent;
    &:after {
      transition: inherit;
      top: 0;
      height: calc(100% + 1px);
    }
  }

  &--light {
    color: $color-light1;
    &:hover {
      color: $color-dark1;
      border-bottom-color: $color-light1;
      &:after {
        background-color: $color-light1;
      }
    }
  }
  &--dark {
    color: $color-dark1;
    &:hover {
      color: $color-light1;
      border-bottom-color: $color-dark1;
      &:after {
        //background-color: $color-dark1;
        @include baseGradientLinearByIdName(dark);
      }
    }
  }
}
