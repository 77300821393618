@keyframes subgroupshow {
    0% {
        transform: translateX(100%);
        z-index: 1;
    }

    100% {
        transform: translateX(0%);
        z-index: 1;
    }
}

@keyframes subgrouphide {
    0% {
        transform: translateX(0%);
        z-index: 1;
    }

    98% {
        transform: translateX(100%);
        z-index: 1;
    }

    100% {
        z-index: 0;
    }
}


// Navigation show on mobile
@keyframes navshow_mobile {
    0% {
        width: 0;
        right: -100%;
        transform: translateX($mobile-nav-w);
    }

    100% {
        width: $mobile-nav-w;
        right: 0;
        transform: translateX(0);
    }
}
// Navigation hide on mobile
@keyframes navhide_mobile {
    0% {
        width: $mobile-nav-w;
        right: 0;
        transform: translateX(0);
    }

    100% {
        width: 0;
        right: -100%;
        transform: translateX($mobile-nav-w);
    }
}


// Navigation show on tablet
@keyframes navshow_tablet {
    0% {
        width: 0;
        transform: translateX($tablet-nav-w);
    }

    100% {
        width: $tablet-nav-w;
        transform: translateX(0);
    }
}
// Navigation hide on tablet
@keyframes navhide_tablet {
    0% {
        width: $tablet-nav-w;
        transform: translateX(0);
    }

    100% {
        width: 0;
        transform: translateX($tablet-nav-w);
    }
}


@keyframes makex_top {
    0% {
        transform: translateY(- $slice-h - $slice-space) rotate(0deg);
        animation-timing-function: ease-out;
    }

    40% {
        height: $slice-h-open;
        transform: translateY(0) rotate(0deg);
        background-color: $slice-bg;
        animation-timing-function: ease-in;
    }

    100% {
        height: $slice-h-open;
        transform: translateY(0) rotate(135deg) scaleX(1.6);
        background-color: $slice-bg-open;
    }
}

@keyframes makex_mid {
    0% {
        transform: scale(1);
    }

    40% {
        transform: scale(1);
    }

    44% {
        transform: scale(0);
    }

    100% {
        transform: scale(0);
    }
}

@keyframes makex_bot {
    0% {
        transform: translateY($slice-h + $slice-space) rotate(0deg);
        animation-timing-function: ease-out;
    }

    40% {
        height: $slice-h-open;
        transform: translateY(0) rotate(0deg);
        background-color: $slice-bg;
        animation-timing-function: ease-in;
    }

    100% {
        height: $slice-h-open;
        transform: translateY(0) rotate(-315deg) scaleX(1.6);
        background-color: $slice-bg-open;
    }
}