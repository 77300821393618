// Styles for ContentBlock Component (RichText)
%ContentBlock {
    line-height: 1.5;
    ul:not(:last-child), ol:not(:last-child) {
        padding: 0 0 4rem;

        li{
            margin-left: 2rem;
        }
    }

    p:not(:last-child) {
        padding: 0 0 4rem;
    }

    h2, h3 {
        @include fontSize($fontSize-variant4);
        @include mediaQueries(S, 'min') {
            @include fontSize($fontSize-variant3);
        }
    }
    h4, h5, h6 { margin-bottom: 2rem; }
    h2, h3, h4, h5, h6 {
        padding-bottom: 3rem;
        + ul, + ol {
            margin-top: -1.5rem;
        }
    }
}
