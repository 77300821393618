@mixin componentSpace(
  $mobileSpace-top: $componentSpace_mobile,
  $mobileSpace-bottom: $componentSpace_mobile,
  $desktopSpace-top: $componentSpace,
  $desktopSpace-bottom: $componentSpace
) {
  @if ($mobileSpace-top == null) {
    $mobileSpace-top: $componentSpace_mobile;
  } @else if ($mobileSpace-bottom == null) {
    $mobileSpace-bottom: $componentSpace_mobile;
  } @else if ($desktopSpace-top == null) {
    $desktopSpace-top: $componentSpace;
  } @else if ($desktopSpace-bottom == null) {
    $desktopSpace-bottom: $componentSpace;
  }

  width: $layout-container;
  max-width: 100%;
  margin-top: $mobileSpace-top;
  margin-right: auto;
  margin-bottom: $mobileSpace-bottom;
  margin-left: auto;

  // @include mediaQueries(S, 'min'){
  //     margin-top: $desktopSpace-top;
  //     margin-bottom: $desktopSpace-bottom;
  // }

  @include mediaQueries(M, "min") {
    margin-top: $desktopSpace-top;
    margin-bottom: $desktopSpace-bottom;
  }

  // TODO: it needs move to separate mixin
  &--custom {
    margin-top: $spaceInsideElement / 2;
    margin-bottom: $spaceInsideElement / 2;
    padding-top: $spaceInsideElement;
    padding-bottom: $spaceInsideElement;
    // @include mediaQueries(S, 'min'){
    //     padding-top: $spaceInsideElement;
    //     padding-bottom: $spaceInsideElement;
    // }
  }
}
