$nav-bg-start: #262626;
$nav-bg-end: #3f3f3f;

$mobile-nav-w: 100vw;
$mobile-nav-h: 100vh;

$tablet-nav-w: 500px;

$desktop-nav-w: 100%;
$desktop-nav-h: 500px; //548px;
$desktop-nav-space: 40px;

$mobile-anchor-pad: 16px 50px 16px 40px;
$mobile-anchor-marg: 2px 0;

$desktop-anchor-pad-v: 6px;
$desktop-anchor-pad-h: 14px;

$mobile-anchor-fs: 1.166666666em;
$mobile-anchor-back: 1.33333333em;

$desktop-anchor-fs: 17px;
$huge-anchor-fs: 24px;

$mobile-title-fs: .8888888em;
$desktop-title-fs: 1em;
$huge-title-fs: 48px;

$mobile-txt-color: #fff;
$desktop-txt-color: inherit;

$nav-highlight-color1: $color-step1;

$nav-hover-color: $color-step1;

$nav-animation-duration: .2s;



// button
$btn-pad: 22px;
$slice-h: 3px;
$slice-h-open: 2px;
$slice-w: 20px;
$slice-w-open: 32px;
$slice-space: 4px;
$slice-bg: #000;
$slice-bg-open: #fff;
$slice-radius: 3px;