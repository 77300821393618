@import "../utils/index";

html {
    &.is-opened-popup {
        overflow: hidden;
        #aside {
            position: fixed;
            background-color: rgba(0,0,0,0.66);
            transition: background-color 0.3s ease-in-out;
            top: 0;
            /* left: 0; */
            /* right: 0; */
            /* bottom: 0; */
            z-index: 1;
            height: 100vh;
            width: 100vw;
        }
    }
}

#aside {
    background-color: transparent;
    transition: background-color 0.3s ease-in-out; 
}
.popup {
    z-index: 1000;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    .c-FormContact {
        padding: 0;
        padding-left: 0.3rem;
    }
}

.popup-backdrop {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1001;
    position: absolute;
    top: 0; bottom: 0; right: 0; left: 0;
}

.popop-content {
    width: 90%;
    max-width: 87rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1001;
    background-color: $color-white;
    padding: 3rem;
    .container {
        margin: 0;
        &__inner {
            padding: 0 3rem 0 0;
            @include mediaQueries(M, "min") {
                padding: 3rem;
            }
        }
    }
}

.popop-wrapper {
    max-height: 50rem;
    overflow-y: auto;
    scrollbar-width: thin;
}

.popup-close {
    padding: 0.3rem 0.75rem 0.5rem;
    font-size: 3rem;
    border: 0;
    position: absolute;
    top: 0;
    right:0;
    line-height: 1;
    transform: translate(50%, -50%);
    z-index: 1002;
    cursor: pointer;
    color: $color-black;
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  top: 50%;
  transform: translateY(-50%);
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: $color-black;
  opacity: 0.6;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

.loader-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0,0,0, 0.2);
}
