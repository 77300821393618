@import "../../assets/styles/utils/index";

$ProductDeliveryProcess_M-numberBox-width: 70px;

@include mediaQueries(M, 'max') {

.c-ProductDeliveryProcess {
    margin: 0 ($space_2 * -1);
    &__processes {
        margin: $space_2 0 0 0;
        display: flex;
        flex-direction: column;

        dd {
            &:last-of-type {
                padding-bottom: 0;
            }
        }
    }

    .component__heading {
        padding-left: $space_2;
        padding-right: $space_2;
    }
}

.process {
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    width: 100%;

    &-info {
        position: relative;
        display: inline-flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        // justify-content: flex-start;
        cursor: pointer;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: $ProductDeliveryProcess_M-numberBox-width;
            width: calc(100% - #{$ProductDeliveryProcess_M-numberBox-width});
            height: 1px;
            background-color: $color_light2;
        }
    
        &__number {
            display: flex;
            justify-content: center;
            align-items: center;
            min-width: $ProductDeliveryProcess_M-numberBox-width;
            min-height: $ProductDeliveryProcess_M-numberBox-width;
            font-weight: 700;
            line-height: 1;
            @include fontSize($fontSize-variant3_1);
            @include baseGradientLinearByIdName(dark);
            color: #ffffff;
        }
        
    
        &__name {
            font-weight: 700;
            font-size: 2rem;
            padding-left: 15px;
            text-transform: uppercase;
            flex-grow: 2;
            line-height: 1;
            @include mediaQueries(XS, 'max'){
                padding-right: 60px;
                word-break: break-word;
            }
            @media all and (max-width: 360px){
               // font-size: 1.1rem;
            }

        }
    
        &__btn--collapse {
            transition: transform 0.3s ease-in-out;
            fill: $color-dark1;
            transform: rotate(-90deg);
            height: 25px;
            position: absolute;
            top: 50%;
            margin-top: -12.5px;
            right: $space_2;

            .is-active & {
                transform: rotate(90deg);
                transition: transform 0.3s ease-in-out;
            }
        }

        &__btn--arrow{
            display: none;
        }
    }

    &-details {
        flex-direction: column;
        display: flex;
        padding: $space_2;
        bottom: 0;
        display: none;

        &.is-active {
            display: flex;
        }

        &__text {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: $space_2;
            
            &__title {
                font-weight: 700;
                @include fontSize($fontSize_variant3_1);
                margin-bottom: $space_3;
            }

            &__description {
                font-weight: 300;
                line-height: 2.5rem;
                margin-bottom: $space_3;
                @include fontSize($fontSize_variant5);
            }

            &__more {
                display: inline-flex;
                text-align: center;
                font-weight: 700;
                padding: 1.5rem 2.2rem;
                @include fontSize($fontSize_variant5);
                text-transform: uppercase;
                border-bottom: 1px solid $color-dark3;
                letter-spacing: 0.2rem;
                text-decoration: none;
                line-height: 2rem;
                color: #000000;
            }
        }

        &__includedElements {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            &__title {
                font-weight: 700;
                @include fontSize($fontSize_variant4);
                margin-bottom: $space_3;
            }
        }

        &__includedElement {
            font-weight: 700;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            padding: 1.5rem 2.5rem;
            margin: 0 0 1rem 0;
            @include fontSize($fontSize_variant6);
            text-transform: uppercase;
            background-color: transparent;
            height: 40px;
            color: $color-white;
            &.color-txt--white {
                color: $color-white;
            }
        }
    }
}

}
