@import "../utils/index";

%TextColumns_cols2 {
    display: grid;
    @include mediaQueries(XS, 'min') {
        grid-template-columns: repeat(1, 1fr);
        column-gap: 3rem;
    }
    @include mediaQueries(S, 'min') {
        grid-template-columns: repeat(2, 1fr);
        column-gap: 12rem;
    }
}