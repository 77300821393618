.color-txt--white { color: $color-white; }

.color-txt--primary {
    color: $color-primary;
}
.color-txt--secondary {
    color: $color-secondary;
}

.color-bg--primary {
    background-color: $color-primary;
}
.color-bg--secondary {
    background-color: $color-secondary;
}

@each $variantColorClass, $variantColor in $variantColors {
    .color-txt--#{$variantColorClass} {
      color: #{$variantColor};
    }
    .color-bg--#{$variantColorClass} {
        background-color: #{$variantColor}; 
    }

    // .color-txt--#{$variantColorClass} {
    //     color: colorTxtBasedOnVariantColor($variantColorClass);
    // }
}

.color-txt--dark1 {
    color: $color-dark1;
}
.color-txt--dark2 {
    color: $color-dark2;
}

.color-txt--light1 {
    color: $color-light1;
}


.color-bg--dark1 {
    background-color: $color-dark1;
}
.color-bg--dark2 {
    background-color: $color-dark2;
}

.color-bg--light1 {
    background-color: $color-light1;
}
